import React from "react";
import "./FooterInfo.css";

const FooterInfo = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-info-container">
      <div className="footer-logo-section">
        <img
          src="/img/Logo_girl_trans.png"
          alt="ArtMe Holst Logo"
          className="footer-logo"
        />
        <p className="footer-paragraph">
          ArtMe Holst – виробник повного циклу картин на полотні: від ідеї в
          Вашій голові до картини на Вашій стіні
        </p>
        <div className="footer-social-links">
          <a href="https://t.me/ARTME_holst">
            <img src="/img/icons/tg.png" alt="Telegram" />
          </a>
          <a href="#">
            <img src="/img/icons/vber.png" alt="Viber" />
          </a>
          <a href="https://instagram.com/artme_holst">
            <img src="/img/icons/ig.png" alt="Instagram" />
          </a>
          <a href="#">
            <img src="/img/icons/tt.png" alt="TikTok" />
          </a>
        </div>
        <p className="footer-paragraph">
          artmeholst.com | {currentYear} | Build: #000030
        </p>
      </div>
      <div className="footer-links-section">
        <div className="footer-links-column">
          <h4>Інформація:</h4>
          <ul>
            <li>
              <a href="/">Головна</a>
            </li>
            <li>
              <a href="/about">Про нас</a>
            </li>
            <li>
              <a href="/faq">FAQ</a>
            </li>
            <li>
              <a href="/contacts">Контакти</a>
            </li>
            <li>
              <a href="#">Умови доставки</a>
            </li>
            <li>
              <a href="#">Умови оплати</a>
            </li>
            <li>
              <a href="#">Знижки та акції</a>
            </li>
          </ul>
        </div>
        <div className="footer-links-column">
          <h4>Категорії:</h4>
          <ul>
            <li>
              <a href="#">Шайн арт</a>
            </li>
            <li>
              <a href="#">Вектор</a>
            </li>
            <li>
              <a href="#">Ілюстрація на фото</a>
            </li>
            <li>
              <a href="#">Живопис</a>
            </li>
            <li>
              <a href="#">Історичні</a>
            </li>
            <li>
              <a href="#">Заміна фону</a>
            </li>
            <li>
              <a href="#">Колажі</a>
            </li>
            <li>
              <a href="#">Мультяшні</a>
            </li>
            <li>
              <a href="#">Реалізм</a>
            </li>
            <li>
              <a href="#">Ілюстрація спогадів</a>
            </li>
          </ul>
        </div>
        <div className="footer-links-column">
          <h4>Правова інформація:</h4>
          <ul>
            <li>
              <a href="#">Угода користувача</a>
            </li>
            <li>
              <a href="#">Обмін - повернення товару</a>
            </li>
            <li>
              <a href="#">Авторське право</a>
            </li>
            <li>
              <a href="#">Договір публічної оферти</a>
            </li>
            <li>
              <a href="#">Персональні дані</a>
            </li>
            <li>
              <a href="#">Політика Cookies</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;
