import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation and Link
import "./Navbar.css";
import { UserContext } from "./UserContext";
import SignInPopup from "./SignInPopup";
import { auth, db } from "../core/firebase";
import { signOut } from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore"; // Import Firestore functions

const Navbar = () => {
  const user = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  const location = useLocation(); // Get current route

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Calculate total items and total price based on cart items
  const totalItems = cartItems.length;
  const totalPrice = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0,
  );

  // Real-time Firestore listener for cart updates
  useEffect(() => {
    if (user) {
      const cartRef = doc(db, "carts", user.uid); // Firestore doc reference to the user's cart

      const unsubscribe = onSnapshot(cartRef, (doc) => {
        if (doc.exists()) {
          const items = doc.data().items || [];
          setCartItems(items);
        }
      });

      // Clean up listener on unmount
      return () => unsubscribe();
    }
  }, [user]);

  // Function to determine if a link is active
  const isActive = (path) => location.pathname === path;

  return (
    <div>
      <div className="upper-navbar">
        <div className="icons">
          <Link to="/">🔍</Link>
          <Link to="/">👤</Link>
          <Link to="/cart">
            🛒 {totalItems} ({totalPrice} UAH)
          </Link>
          {user ? (
            <>
              <Link to="/user">Hello, {user.displayName || user.email}</Link>
              <a onClick={handleLogout} style={{ cursor: "pointer" }}>
                Logout
              </a>
            </>
          ) : (
            <a onClick={() => setShowPopup(true)} style={{ cursor: "pointer" }}>
              Login
            </a>
          )}
        </div>
      </div>
      <nav className="navbar">
        <div className="logo">
          <img src="/img/Logo_girl_trans.png" className="left-img" alt="logo" />
          <a href="/">
            <img
              src="/img/Logo_letters_2.png"
              className="right-img"
              alt="logo"
            />
          </a>
        </div>
        <div className="nav-links styled">
          <Link to="/about" className={isActive("/about") ? "active" : ""}>
            Про нас
          </Link>
          <Link to="/gallery" className={isActive("/gallery") ? "active" : ""}>
            Галерея
          </Link>
          <Link to="/styles" className={isActive("/styles") ? "active" : ""}>
            Стилі
          </Link>
          <Link to="/price" className={isActive("/price") ? "active" : ""}>
            Розміри/Ціни
          </Link>
          <Link
            to="/order/1"
            className={`blue-border ${isActive("/order/1") ? "active" : ""}`}
          >
            Замовити з промальовкою
          </Link>
          <Link
            to="/order/2"
            className={`orange-border ${isActive("/order/2") ? "active" : ""}`}
          >
            Замовити без промальовки
          </Link>
        </div>
      </nav>
      {showPopup && <SignInPopup closePopup={() => setShowPopup(false)} />}
    </div>
  );
};

export default Navbar;
