import React, { useState } from "react";
import "./SizeChooser.css"; // Make sure to add this CSS file

const SizeChooser = () => {
  const [selectedCategory, setSelectedCategory] = useState("portrait");
  const [selectedSize, setSelectedSize] = useState(null);

  const categories = {
    portrait: {
      S: ["20x30", "30x40", "40x50", "40x60", "50x60"],
      M: ["50x70", "60x80", "60x90", "70x80", "70x100", "80x120"],
      L: ["100x150", "100x200"],
    },
    album: {
      S: ["30x20", "40x30", "50x40", "60x40", "60x50"],
      M: ["70x50", "80x60", "90x60", "80x70", "100x70", "120x80"],
      L: ["150x100", "200x100"],
    },
    square: {
      S: ["30x30", "50x50"],
      M: ["60x60", "80x80"],
      L: ["100x100"],
    },
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedSize(null); // Reset size on category change
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  return (
    <div className="size-chooser">
      <div className="left-column">
        <button
          onClick={() => handleCategoryChange("portrait")}
          className={selectedCategory === "portrait" ? "selected" : ""}
        >
          Портретна
        </button>
        <button
          onClick={() => handleCategoryChange("album")}
          className={selectedCategory === "album" ? "selected" : ""}
        >
          Альбомна
        </button>
        <button
          onClick={() => handleCategoryChange("square")}
          className={selectedCategory === "square" ? "selected" : ""}
        >
          Квадратна
        </button>
      </div>

      <div className={`right-container ${selectedCategory}`}>
        <h3>Розміри:</h3>
        {Object.keys(categories[selectedCategory]).map((sizeClass) => (
          <div key={sizeClass} className="size-class">
            <h4>{sizeClass.toUpperCase()}</h4>
            <div className="size-options">
              {categories[selectedCategory][sizeClass].map((size) => (
                <button
                  key={size}
                  className={selectedSize === size ? "selected" : ""}
                  onClick={() => handleSizeSelect(size)}
                >
                  {size}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SizeChooser;
