import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "./UserContext";
import { db } from "../core/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import "./OrdersHistory.css"; // Optional: Add custom styles here

const OrdersHistory = () => {
  const user = useContext(UserContext); // Get the current user
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedOrder, setExpandedOrder] = useState(null); // Track the expanded order

  // Fetch user orders from Firestore
  useEffect(() => {
    const fetchOrders = async () => {
      if (user) {
        const ordersRef = collection(db, "orders");
        const q = query(ordersRef, where("userId", "==", user.uid)); // Fetch only the user's orders
        const querySnapshot = await getDocs(q);

        const userOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(userOrders);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user]);

  // Toggle expand/collapse for an order
  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  if (loading) {
    return <p>Loading your order history...</p>;
  }

  if (orders.length === 0) {
    return <p>You have no orders yet.</p>;
  }

  return (
    <div className="orders-history-container">
      <h2>Your Orders</h2>
      {orders.map((order) => (
        <div key={order.id} className="order-card">
          <div
            className="order-summary"
            onClick={() => toggleOrderDetails(order.id)}
          >
            <h3>Order ID: {order.id}</h3>
            <p>
              <strong>Status:</strong> {order.status}
            </p>
            <p>
              <strong>Total Price:</strong> {order.total} UAH
            </p>
            <p>
              <strong>Created At:</strong>{" "}
              {new Date(order.createdAt.seconds * 1000).toLocaleDateString()}
            </p>
            <button className="toggle-details-btn">
              {expandedOrder === order.id ? "Hide Details" : "Show Details"}
            </button>
          </div>

          {expandedOrder === order.id && (
            <div className="order-details">
              <h4>Items:</h4>
              <ul>
                {order.items.map((item) => (
                  <li key={item.id}>
                    <p>
                      <strong>Style:</strong> {item.selectedStyle}
                    </p>
                    <p>
                      <strong>Size:</strong> {item.selectedSize}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {item.quantity}
                    </p>
                    <p>
                      <strong>Price:</strong> {item.price} UAH
                    </p>
                    <img
                      src={item.imageURL}
                      alt={item.selectedStyle}
                      className="order-item-image"
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OrdersHistory;
