import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import hooks from React Router
import "./ArtStyles.css"; // Import the CSS file

const ArtStylesComponent = () => {
  const [artStyles, setArtStyles] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [sliderPosition, setSliderPosition] = useState(60); // Initial position of the slider (60%)
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchArtStyles = async () => {
      try {
        const response = await fetch("/data/artstyles.json");
        const data = await response.json();
        setArtStyles(data.artStyles);
        // Get the styleId from the URL and set the selected style
        const params = new URLSearchParams(location.search);
        const styleId = params.get("styleId");
        if (styleId) {
          const foundStyle = data.artStyles.find(
            (style) => style.id === styleId,
          );
          if (foundStyle) {
            setSelectedStyle(foundStyle);
          } else {
            setSelectedStyle(data.artStyles[0]); // Default to the first style if not found
          }
        } else {
          setSelectedStyle(data.artStyles[0]); // Default to the first style
        }
      } catch (error) {
        console.error("Error fetching art styles:", error);
      }
    };

    fetchArtStyles();
  }, [location.search]); // Re-fetch when the URL changes

  const handleStyleSelect = (style) => {
    setSelectedStyle(style);
    // Update the URL with the selected style's ID
    navigate(`?styleId=${style.id}`);
  };

  const handleOrderClick = () => {
    console.log("Order for:", selectedStyle);
  };

  const handleSliderMouseDown = (e) => {
    const slider = sliderRef.current;
    const handleMouseMove = (event) => {
      const rect = slider.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const percentage = Math.min(Math.max(offsetX / rect.width, 0), 1) * 100;
      setSliderPosition(percentage);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div className="art-styles-container">
      {/* Стилі Column */}
      <div className="column styles-column">
        <h2>Стилі</h2>
        <ul className="styles-list">
          {artStyles.map((style) => (
            <li
              key={style.id}
              onClick={() => handleStyleSelect(style)}
              className={`style-item ${selectedStyle?.id === style.id ? "active" : ""}`}
            >
              {style.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Опис Column */}
      <div className="column description-column">
        <h2>Опис</h2>
        {selectedStyle ? (
          <p>{selectedStyle.description}</p>
        ) : (
          <p>Select a style to see the description.</p>
        )}
      </div>

      {/* Preview Column */}
      <div className="column preview-column">
        <h2>Preview</h2>
        {selectedStyle && (
          <div className="preview-container">
            <img
              src={selectedStyle.beforeImage}
              alt="Before"
              className="before-image"
              style={{
                clipPath: `inset(0 ${100 - sliderPosition}% 0 0)`,
              }}
            />
            <img
              src={selectedStyle.afterImage}
              alt="After"
              className="after-image"
              style={{
                clipPath: `inset(0 0 0 ${sliderPosition}%)`,
              }}
            />
            <div
              ref={sliderRef}
              onMouseDown={handleSliderMouseDown}
              className="slider"
            />
          </div>
        )}
        <button onClick={handleOrderClick} className="order-button">
          Order
        </button>
      </div>
    </div>
  );
};

export default ArtStylesComponent;
