import React from "react";
import SizeChooser from "../components/SizeChooser";

const DIY = () => {
  return (
    <div>
      <SizeChooser />
    </div>
  );
};

export default DIY;
