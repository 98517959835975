import React from "react";
import "./Steps.css"; // Include your styles here

const Steps = () => {
  return (
    <div className="steps-container">
      <h1 className="steps-title">Як це працює?</h1>
      <div className="steps-flow">
        <div className="step">
          <h3>Step 1</h3>
          <p>
            В розділі <a href="/rozmiri">«Розміри»</a> ознайомтесь з розмірами
            картин та як вона впишеться в інтер'єр квартири
          </p>
        </div>
        <div className="arrow">➔</div>
        <div className="step">
          <h3>Step 2</h3>
          <p>
            В розділі <a href="/kalkuliator">«Калькулятор»</a> завантажте бажане
            фото, з якого Ви б хотіли створити картину
          </p>
        </div>
        <div className="arrow">➔</div>
        <div className="step">
          <h3>Step 3</h3>
          <p>
            Оберіть стиль, розмір та інші параметри, згідно Ваших вподобань та
            смаків для майбутньої картини
          </p>
        </div>
        <div className="arrow">➔</div>
        <div className="step">
          <h3>Step 4</h3>
          <p>
            Після оформлення замовлення, з Вами обов'язково зв'яжеться менеджер
            для уточнення всіх деталей та фіналізації вартості замовлення
          </p>
        </div>
        <div className="arrow">➔</div>
        <div className="step">
          <h3>Step 5</h3>
          <p>Очікувати Ваше замовлення 😎</p>
        </div>
      </div>
    </div>
  );
};

export default Steps;
